import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import ActionDetail from "./ActionDetail";
import { parseWithOptions } from "date-fns/fp";

export default function SwipeableTemporaryDrawer(props) {
  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    props.setDrawer(open);
  };

  let anchor = "right";

  //  'left', 'right', 'top', 'bottom'

  return (
    <div>
      <React.Fragment key="right">
        {/* <div>{JSON.stringify(props.drawer)}</div>
        <Button onClick={toggleDrawer(true)}>right</Button> */}
        <SwipeableDrawer
          anchor="right"
          open={props.drawer}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          <Box
            sx={{
              width: anchor === "top" || anchor === "bottom" ? 1200 : 250,
            }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
          >
            <ActionDetail id={props.currentAction} />
          </Box>
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
