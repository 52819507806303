import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Config from "../ConfigHelper";

import axios from "axios";

import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "react-query";

const API_URL = Config.getApiURL();

const POINTS = ["2", "5", "10", "100", "1000"];

function getPoints() {
  return POINTS[Math.floor(Math.random() * POINTS.length)];
}

function handleComp(
  id,
  completed,
  setDone,
  done,
  extraFunc,
  durationArg,
  setCelebrate
) {
  const time = new Date();
  let points = getPoints();
  let notes = "foo";
  let duration = durationArg;
  if (typeof extraFunc == "function") {
    extraFunc();
  }
  setDone(
    done.concat([
      {
        id: id,
        time: time,
        points: points,
        notes: notes,
        duration: duration,
      },
    ])
  );
  if (completed === 1) setCelebrate(true);

  setTimeout(() => {
    axios.post(API_URL + "/completions", {
      completion: {
        action_id: id,
        status: completed ? 1 : 0, //completed,
        points: points,
        notes: notes,
        duration: duration,
      },
    });
  }, 1000);

  setTimeout(() => {
    setCelebrate(false);
  }, 1000); //ms
}

export default handleComp;
