import React, { useState } from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

function Form(props) {
  const [name, setName] = useState("");
  const [age, setAge] = React.useState("");
  const [recurrence, setRecurrence] = useState("");
  const [interval, setInterval] = useState("");
  const [action, setAction] = useState("");

  const [state, setState] = useState({
    action: "",
    interval: "",
    scheduleType: "",
  });

  function handleSubmit(e) {
    console.log(e.target);
    e.preventDefault();
    console.log(state);
    // props.addAction(name);
    // setName("");
  }

  function handleChange(e) {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    console.log(name);
    console.log(value);
    setState({
      [name]: value,
    });
    console.log(state);
    // setName(e.target.value);
  }

  const SCHEDULE_TYPES = ["daily", "hour", "day", "month", "onetime"];

  const scheduleTypeListOptions = SCHEDULE_TYPES.map((name) => (
    <MenuItem value={name}>{name}</MenuItem>
  ));

  return (
    <form onSubmit={handleSubmit}>
      <h2>Add todo</h2>
      <div>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Age</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={age}
            label="Recurrence"
            onChange={handleChange}
          >
            {scheduleTypeListOptions}
          </Select>
        </FormControl>
      </div>
      <div>
        <TextField
          id="standard-basic"
          label="Action"
          name="action"
          onChange={handleChange}
          variant="standard"
        />
      </div>
      <div>
        <TextField
          id="standard-basic"
          label="Interval"
          name="interval"
          onChange={handleChange}
          variant="standard"
        />
      </div>
      <Button variant="outlined">Add</Button>
    </form>
  );
}

export default Form;
