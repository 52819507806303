import React from "react";

let links = [];

links.push({ name: "Open Things", url: "things:///" });
links.push({ name: "Add Thing", url: "things:///add?show-quick-entry=true" });
links.push({ name: "env local", url: "http://192.168.86.22:3001" });
links.push({ name: "rhythm-dev", url: "https://rhythm-dev.onrender.com" });
links.push({ name: "prod", url: "https://pairs.onrender.com" });

export default function LaunchCenter(props) {
  return links.map((link) => (
    <div>
      <a href={link.url}>{link.name}</a>
    </div>
  ));
}
