import React from "react";
import { Warning, Info, NotificationsActive, Done } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

function iconStyles() {
  return {
    alarm: {
      color: "red",
    },
    warn: {
      color: "orange",
    },
    info: {
      color: "blue",
    },
  };
}

function CardIcon(props) {
  const classes = makeStyles(iconStyles)();

  function getIcon(action, completed) {
    switch (true) {
      case completed:
        return <Done className={classes.info} />;
      case action.isDue <= 0: // overdue
        return <NotificationsActive className={classes.alarm} />;
      case action.isDue < 2: // due in 1 hour
        return <Warning className={classes.warn} />;
      case action.isDue < 10: // due in several hours
        return <Info className={classes.info} />;
      case action.isDue > 24 && action.scheduleType === "daily":
        return <Done className={classes.info} />;
      default:
        return <Info className={classes.info} />;
    }
  }

  return getIcon(props.action, props.completed);
}

export default CardIcon;
