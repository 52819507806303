import { format, getDay, isWithinInterval } from "date-fns";

export const shouldAppear = (
  label,
  dayList,
  visibile_within_hrs,
  hide_after_hrs,
  isDueToday
) => {
  let hr_filter = true;
  let day_of_week_filter = true;
  // console.log(visibile_within_hrs);
  // return true;
  // console.log("within " + visibile_within_hrs);
  // console.log("hide " + hide_after_hrs);

  if (
    visibile_within_hrs &&
    hide_after_hrs &&
    visibile_within_hrs !== null &&
    hide_after_hrs !== null &&
    typeof visibile_within_hrs === "number" &&
    typeof hide_after_hrs === "number"
  ) {
    hr_filter = withinHourInterval(visibile_within_hrs, hide_after_hrs);
  }
  if (dayList) {
    day_of_week_filter = withinDayInterval(dayList);
  }
  // console.log(label);
  // console.log("hr");
  // console.log(hr_filter);
  // console.log("dow");
  // console.log(day_of_week_filter);
  // console.log("today");
  // console.log(isDueToday);
  // if (!hr_filter || !day_of_week_filter || !isDueToday) {
  //   return false;
  // } else {
  //   return true;
  // }
  return hr_filter && day_of_week_filter && isDueToday;
};

export const withinHourInterval = (visible_within_hrs, hide_after_hrs) => {
  let now = new Date();

  // @todo - remove hack
  if (hide_after_hrs < visible_within_hrs) {
    hide_after_hrs += 12;
  }

  let periodStart = new Date();
  periodStart.setHours(visible_within_hrs);
  periodStart.setMinutes(0);
  periodStart.setSeconds(0);

  let periodEnd = new Date();
  periodEnd.setHours(hide_after_hrs);
  periodEnd.setMinutes(0);
  periodStart.setSeconds(0);

  try {
    return isWithinInterval(now, { start: periodStart, end: periodEnd });
  } catch (error) {
    console.log(periodStart);
    console.log(periodEnd);
    console.log(typeof periodStart);
    return true;
  }
  // get hour - convert to seconds from midnight
  // get start time - convert to seconds
  // get end time - convert to seconds
};

// Sun = day 0
export const withinDayInterval = (days) => {
  let now = new Date();
  let curDayWeek = getDay(now);
  return days.includes(String(curDayWeek));
};
