import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import LocalOfferIcon from "@mui/icons-material/LocalOfferOutlined";
import CheckIcon from "@mui/icons-material/Check";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { SettingsEthernet } from "@mui/icons-material";

function FilterMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [tags, setTags] = React.useState(props.initialTags);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    // console.log(tags);
  });

  function toggleTag(tags, tag) {
    if (tags.includes(tag)) {
      const index = tags.indexOf(tag);
      if (index > -1) {
        tags.splice(index, 1);
      }
      // remove
    } else {
      tags.push(tag);
    }
    props.setTags(tags);
  }

  return (
    <div>
      <Button
        id="demo-positioned-button"
        aria-controls="demo-positioned-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        variant="outlined"
        startIcon={<ArrowDropDownIcon />}
      >
        {props.text_button ? props.text_button : <LocalOfferIcon />}
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {props.menuOptions.map((tag) => (
          <MenuItem
            onClick={function () {
              if (props.max_opts === "1") {
                setTags([tag]);
                props.setTags([tag]);
              } else {
                if (tags.includes(tag)) {
                  const filteredTags = tags.filter((item) => item !== tag);
                  setTags(filteredTags);
                  props.setTags(filteredTags);
                  // remove
                } else {
                  setTags(tags.concat(tag));
                  props.setTags(tags);
                }
                toggleTag(tags, tag);
              }

              //handleClose();
            }}
            tags={tags}
          >
            {tags.includes(tag) ? (
              <CheckBoxIcon />
            ) : (
              <CheckBoxOutlineBlankIcon />
            )}
            {tag}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

export default FilterMenu;
