import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Config from "../ConfigHelper";
import CircularProgress from "@mui/material/CircularProgress";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";

import axios from "axios";

import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "react-query";

const API_URL = Config.getApiURL();

const POINTS = ["2", "5", "10", "100", "1000"];

function getPoints() {
  return POINTS[Math.floor(Math.random() * POINTS.length)];
}

function ActionButton(props) {
  const mutation = useMutation((formData) => {
    return axios.post(API_URL + "/completions", formData);
  });

  let style = props.style ? props.style : {};
  let size = props.size ? props.size : "small";
  let variant = props.variant ? props.variant : "text";

  function handleComp(id, completed) {
    const time = new Date();
    let points = getPoints();
    let notes = "foo";
    let duration = props.duration;
    if (typeof props.extraFunc == "function") {
      props.extraFunc();
    }
    props.setDone(
      props.done.concat([
        {
          id: id,
          time: time,
          points: points,
          notes: notes,
          duration: duration,
        },
      ])
    );
    if (completed === 1) props.setCelebrate(true);

    setTimeout(() => {
      mutation.mutate({
        completion: {
          action_id: id,
          status: completed ? 1 : 0, //completed,
          points: points,
          notes: notes,
          duration: duration,
        },
      });
    }, 100);

    setTimeout(() => {
      props.setCelebrate(false);
    }, 1000); //ms
  }

  if (mutation.isLoading)
    return (
      <Button>
        <CircularProgress />
      </Button>
    );

  if (mutation.isError)
    return <div>An error occurred: {mutation.error.message}</div>;

  if (props.isDone || mutation.isSuccess) {
    return (
      <Button>
        <CheckBoxOutlinedIcon />
      </Button>
    );
  }

  const Inner = props.inner; // Note: variable name _must_ start with a capital letter

  if (props.type === "text") {
    return (
      <Button
        size={size}
        variant={variant}
        style={style}
        onClick={() => handleComp(props.id, props.completed)}
      >
        {props.label}
      </Button>
    );
  } else if (props.startIcon)
    return (
      <Button
        size={size}
        variant={variant}
        style={style}
        onClick={() => handleComp(props.id, props.completed)}
        startIcon={props.startIcon}
      >
        <Inner></Inner>
      </Button>
    );
  else
    return (
      <Button
        size={size}
        variant={variant}
        style={style}
        onClick={() => handleComp(props.id, props.completed)}
      >
        <Inner>xxx</Inner>
      </Button>
    );
}

export default ActionButton;
