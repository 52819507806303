import React, { useState } from "react";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";

import { makeStyles, propsToClassKey } from "@mui/styles";
import LocalOfferIcon from "@mui/icons-material/LocalOfferOutlined";
import CheckIcon from "@mui/icons-material/Check";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Divider from "@mui/material/Divider";
import axios from "axios";
import Config from "../ConfigHelper";
import { getTags } from "../ConfigHelper";
import ImportanceIcon from "./ImportanceIcons";
import handleCompletion from "./CompletionService";

import Chip from "@mui/material/Chip";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import TimerIcon from "@mui/icons-material/Timer";
import { isMobile } from "react-device-detect";

import { timeDue } from "../utils";
import ReactTimeAgo from "react-time-ago";
import CardIcon from "./CardIcon";
import SwipeableTemporaryDrawer from "./ActionDetailDrawer";

import Moment from "moment";
import ActionButton from "./ActionButton";
import { PinDropSharp } from "@mui/icons-material";
const API_URL = Config.getApiURL();

function PositionedMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id="demo-positioned-button"
        aria-controls="demo-positioned-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        size="small"
        startIcon={<ArrowDropDownCircleIcon />}
      >
        More
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={props.startTimer}>Start Timer</MenuItem>
        <MenuItem onClick={props.startTimer}>Commit</MenuItem>
        <MenuItem onClick={props.startTimer}>Party</MenuItem>
        <MenuItem onClick={props.startTimer}>Race</MenuItem>
        <MenuItem onClick={props.startTimer}>Zone Out</MenuItem>
        <MenuItem onClick={props.startTimer}>Focus</MenuItem>
        <MenuItem onClick={props.startTimer}>Be Grateful</MenuItem>
        <MenuItem onClick={props.startTimer}>Love yourself</MenuItem>
        <MenuItem onClick={props.startTimer}>Love someone else</MenuItem>
        <MenuItem onClick={props.startTimer}>Earn points $$</MenuItem>
        <Divider />
        <MenuItem
          onClick={function (event) {
            props.handleCompletion();
            handleClose();
          }}
        >
          Skip
        </MenuItem>
        <Divider />
        <MenuItem>
          <Link to={`/edit-action/${props.action_id}`}>Edit</Link>
        </MenuItem>
        <MenuItem onClick={props.details}>Details</MenuItem>
      </Menu>
    </>
  );
}

function setImportance(id, importance) {
  let data = { axtion: { id: id, importance: importance } };
  return axios.patch(API_URL + "/actions/" + id + ".json", data);
}

function toggleTag(id, initialTags, tag, key = "domain_list") {
  if (initialTags.includes(tag)) {
    const index = initialTags.indexOf(tag);
    if (index > -1) {
      initialTags.splice(index, 1);
    }
    // remove
  } else {
    initialTags.push(tag);
  }
  let data = { axtion: { id: id, [key]: initialTags } };
  return axios.patch(API_URL + "/actions/" + id + ".json", data);
}

function TagMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [tags, setTags] = React.useState(props.initialTags);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="demo-positioned-button"
        aria-controls="demo-positioned-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        size="small"
        startIcon={
          props.field === "requirements_list" ? (
            <StopCircleIcon />
          ) : (
            <LocalOfferIcon />
          )
        }
      >
        {props.label}
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {getTags(props.field).map((tag) => (
          <MenuItem
            onClick={function () {
              if (tags.includes(tag)) {
                const filteredTags = tags.filter((item) => item !== tag);
                setTags(filteredTags);
                // remove
              } else {
                setTags(tags.concat(tag));
                console.log("adding " + tag);
                console.log(tags);
              }

              toggleTag(props.id, props.initialTags, tag, props.field);
              //handleClose();
            }}
            tags={tags}
          >
            {tags.includes(tag) ? (
              <CheckBoxIcon />
            ) : (
              <CheckBoxOutlineBlankIcon />
            )}
            {tag}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

function ImportanceMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [tags, setTags] = React.useState(props.initialTags);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="demo-positioned-button"
        aria-controls="demo-positioned-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <ImportanceIcon importance={tags[0]} />
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {[
          "critical",
          "divider",
          "high",
          "medium",
          "low",
          "divider",
          "idea",
          "bonus",
        ].map((tag) =>
          tag === "divider" ? (
            <Divider />
          ) : (
            <MenuItem
              onClick={function () {
                if (tags.includes(tag)) {
                  const filteredTags = tags.filter((item) => item !== tag);
                  setTags(filteredTags);
                  // remove
                } else {
                  setTags([tag]);
                  console.log("adding " + tag);
                  console.log(tags);
                }
                setImportance(props.id, tag);
                // toggleTag(props.id, props.initialTags, tag);
                //handleClose();
              }}
              tags={tags}
            >
              {tags.includes(tag) ? (
                <CheckBoxIcon />
              ) : (
                <CheckBoxOutlineBlankIcon />
              )}
              <ImportanceIcon importance={tag} />
              {tag}
            </MenuItem>
          )
        )}
      </Menu>
    </div>
  );
}

function cardStyles() {
  return {
    critical: {
      // borderLeft: "8px solid red",
      backgroundColor: "rgba(255,255,255,0.85) !important",
      padding: 0,
      "&:last-child": {
        paddingBottom: 0,
      },
    },

    ondeck: {
      // borderLeft: "8px solid orange",
      backgroundColor: "rgba(255,255,255,0.85) !important",
      background: "#000",
    },
    overdue: {
      // borderLeft: "8px solid red",
      backgroundColor: "rgba(255,255,255,0.85) !important",
      padding: 0,
      "&:last-child": {
        paddingBottom: 0,
      },
    },
    ok: {
      // borderLeft: "8px solid green",
      backgroundColor: "rgba(255,255,255,0.85) !important",
    },
  };
}

const ActionRow = (props) => {
  const classes = makeStyles(cardStyles)();

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const [drawer, setDrawer] = React.useState(false);

  function getStatus(action, completed) {
    switch (true) {
      case completed:
        return "ok";
      case action.isDue <= 0: // overdue
        return "overdue";
      case action.isDue < 2: // due in 1 hour
        return "ondeck";
      case action.isDue < 10: // due in several hours
        return "ondeck";
      case action.isDue > 24 && action.scheduleType === "daily":
        return "ok";
      default:
        return "ok";
    }
  }

  function getCardStyle(status, classes) {
    switch (status) {
      case "overdue":
        return classes.overdue;
      case "ondeck":
        return classes.ondeck;
      case "ok":
        return classes.ok;
      default:
        return classes.ok;
    }
  }

  Moment.locale("en");

  const time = new Date();
  time.setSeconds(time.getSeconds() + 6000); // 100 mins

  // props

  // action
  /// id
  /// label
  /// status (completed, doable)
  /// last_completed
  /// completed

  // functions
  /// complete_handler
  /// skip_handler
  /// timer_handler

  // displayOpts
  /// visible - true/false
  /// time due - true/false
  /// icon - true/false
  /// last completed - true/false

  let status = getStatus(props.action, props.completed);

  if (status !== props.status) return "";
  // <span>
  //   <div>
  //     Card hidden:
  //     {status}
  //     {JSON.stringify(props.status)}
  //   </div>
  // </span>

  if (!props.visible) return <></>;

  if (status === props.status)
    return (
      <Grid item xs={12} key={props.action.id} className="list">
        <Card key={props.action.id}>
          <CardContent
            className={
              props.action.importance === "critical"
                ? classes.critical
                : getCardStyle(
                    getStatus(props.action, props.completed),
                    classes
                  )
            }
          >
            <Grid container>
              <Grid item xs={2}>
                <ActionButton
                  id={props.action.id}
                  completed={true}
                  label="Complete"
                  isDone={
                    props.done.filter((action) => {
                      return action.id === props.action.id;
                    }).length > 0
                      ? true
                      : false
                  }
                  setDone={props.setDone}
                  setCelebrate={props.setCelebrate}
                  done={props.done}
                  inner={CheckBoxOutlineBlankIcon}
                >
                  <CheckBoxOutlineBlankIcon />
                </ActionButton>
              </Grid>
              {/* end left grid */}
              {/* start middle grid */}
              <Grid item xs={8} onClick={handleExpandClick}>
                {props.showTimeDue && (
                  <Typography variant="body2">
                    {timeDue(props.action)}
                  </Typography>
                )}
                <Grid container>
                  <Grid item>
                    <Box
                      sx={{
                        display: {
                          xs: "block", //iphone
                          sm: "block",
                          md: "none", //iPad
                          lg: "block",
                          xl: "block", //macbook
                        },
                      }}
                    >
                      <Typography variant="h6">
                        {/* {props.action.importance === "critical" && (
                          <TimerIcon />
                        )} */}

                        {props.action.label}
                        {props.action.estimated_mins &&
                          `(${props.action.estimated_mins}m)`}

                        {/* <br />
                        {props.completed && "completed"}
                        <br />
                        {props.recentlyCompleted && "recently completed"} */}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: {
                          xs: "none", //iphone
                          sm: "none",
                          md: "block", //iPad
                          lg: "none",
                          xl: "none", //macbook
                        },
                      }}
                    >
                      <Typography variant="h5">
                        {props.action.label}
                        {/* {props.action.importance} */}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                {!props.showTimeDue && (
                  <Typography variant="body2">
                    {props.action.lastCompleted ? (
                      <ReactTimeAgo date={props.action.lastCompleted} />
                    ) : (
                      "Not yet"
                    )}
                  </Typography>
                )}
              </Grid>
              {/* end middle col */}
              {/* right col */}
              <Grid item xs={2}>
                <SwipeableTemporaryDrawer
                  drawer={drawer}
                  setDrawer={setDrawer}
                  currentAction={props.action.id}
                />

                <Grid container>
                  {props.completed || props.recentlyCompleted ? (
                    <Button
                      size="small"
                      variant="text"
                      onClick={() => {
                        window.open(
                          "https://hopperab.typeform.com/to/g9bo6hSD"
                        );
                      }}
                    >
                      +notes
                    </Button>
                  ) : (
                    props.showLink &&
                    props.action.link_url && (
                      <Grid item pl={1}>
                        <a
                          href={props.action.link_url}
                          target={isMobile ? "self" : "_blank"}
                        >
                          <OpenInNewIcon />
                        </a>
                      </Grid>
                    )
                  )}
                </Grid>
              </Grid>

              {/* end col */}
            </Grid>
          </CardContent>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <Grid container>
                <Grid item xs={10}>
                  {props.showTags &&
                    props.action.domain_list.map((tag) => (
                      <Chip label={tag} variant="outlined" size="small" />
                    ))}

                  {props.showTags &&
                    props.action.requirement_list.map((tag) => (
                      <Chip
                        label={tag}
                        color="secondary"
                        variant="outlined"
                        size="small"
                      />
                    ))}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item>
                  <Button
                    onClick={() => props.handleTimer(props.action)}
                    size="small"
                  >
                    Commit
                  </Button>
                </Grid>
                <Grid item>
                  <TagMenu
                    id={props.action.id}
                    initialTags={props.action.domain_list}
                    field="domain_list"
                    label="Tag"
                  />
                </Grid>
                <Grid item>
                  <TagMenu
                    id={props.action.id}
                    initialTags={props.action.requirement_list}
                    field="requirement_list"
                    label="Context"
                  />
                </Grid>
                <Grid item>
                  <Button
                    component={Link}
                    size="small"
                    to={`/edit-action/${props.action.id}`}
                  >
                    Edit
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={function () {
                      handleCompletion(
                        props.action.id,
                        false,
                        props.setDone,
                        props.done,
                        false,
                        null,
                        props.setCelebrate
                      );
                      handleExpandClick();
                    }}
                    size="small"
                  >
                    Skip
                  </Button>
                </Grid>
                <Grid item>
                  <PositionedMenu
                    details={function (event) {
                      props.setCurrentAction(props.action.id);
                      setDrawer(true);
                    }}
                    startTimer={() => props.handleTimer(props.action)}
                    action_id={props.action.id}
                    handleCompletion={() =>
                      handleCompletion(
                        props.action.id,
                        false,
                        props.setDone,
                        props.done,
                        false,
                        null,
                        props.setCelebrate
                      )
                    }
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Collapse>
        </Card>
      </Grid>
    );
};

export default ActionRow;
