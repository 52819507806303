import React from "react";
import { useTimer } from "react-timer-hook";
import useInterval from "react-useinterval";
import { Typography } from "@mui/material";

import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";

export default function Timer(props) {
  const time = new Date();
  console.log("new timer for " + props.minutes + " mins");
  const totalmSeconds = props.minutes * 6000; // 6000 ms/min
  const expiryTimestamp = time.setSeconds(time.getSeconds() + totalmSeconds); // 100 mins

  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiryTimestamp,
    onExpire: () => console.warn("onExpire called"),
    autoStart: true,
  });

  useInterval(
    () => {
      props.setTimeElapsed((props.minutes - minutes - 1) * 60 + (60 - seconds));
    },
    isRunning ? 1000 : null
  );

  return (
    <div>
      <div>
        <Typography variant="h1">
          <span>{minutes}</span>:<span>{seconds}</span>
        </Typography>
      </div>
      {!isRunning && (
        <PlayCircleFilledIcon onClick={start}>Start</PlayCircleFilledIcon>
      )}
      {isRunning && <PauseCircleIcon onClick={pause}>Pause</PauseCircleIcon>}
    </div>
  );
}
