import axios from "axios";
import Config from "./ConfigHelper";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "react-query";

const API_URL = Config.getApiURL();

async function createTodo(task) {
  const { data: newTodo } = await axios.post(API_URL, {
    task,
  });
  return newTodo;
}

async function deleteTodo(id) {
  const message = await axios.delete(`${API_URL}${id}`);
  return message;
}

async function completeItem(formData) {
  console.log(formData);

  let url = Config.getApiURL() + "/completions";

  await axios
    .post(url, formData)
    .then(function (response) {
      // handle success
      console.log(response);

      // window.location.reload();
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
    .then(function () {
      // always executed
    });

  // const { data: newTodo } = await axios.put(`${API_URL}${id}`, payload);
  // return newTodo;
}

// const updateTodo2 = async (e, id) => {
//   e.stopPropagation();
//   const payload = {
//     completed: !todos.find((todo) => todo._id === id).completed,
//   };
//   const updatedTodo = await APIHelper.updateTodo(id, payload);
//   setTodos(todos.map((todo) => (todo._id === id ? updatedTodo : todo)));
// };

async function fetchTodos() {
  const { data } = await axios.get(API_URL + "/actions.json");
  return data;
}

async function getAllTodos() {
  const { data: todos } = await axios.get(API_URL + "/actions.json");
  return todos;
}

export default { createTodo, deleteTodo, completeItem, getAllTodos };
