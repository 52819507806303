import * as React from "react";
import useEffect from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { blue } from "@mui/material/colors";

const emails = ["username@gmail.com", "user02@gmail.com"];

function SimpleDialog(props) {
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  let array = [1, 2, 3];
  let reward = array[Math.floor(Math.random() * array.length)];

  const DONE_MSG = [
    "Nice job",
    "Crushing it",
    "Way to go",
    "You da man",
    "That's how we do it",
  ];

  const POINTS = ["2", "5", "10", "100", "1000"];

  function getPoints() {
    return POINTS[Math.floor(Math.random() * POINTS.length)];
  }

  function getMessage() {
    return DONE_MSG[Math.floor(Math.random() * DONE_MSG.length)];
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle></DialogTitle>
      <Box p={3} sx={{ display: reward === 1 ? "block" : "none" }}>
        <iframe
          src="https://giphy.com/embed/1hMdJEwpt04PNiNUob"
          width="480"
          height="316"
          frameBorder="0"
          class="giphy-embed"
          allowFullScreen
        ></iframe>
      </Box>
      <Box p={3} sx={{ display: reward === 2 ? "block" : "none" }}>
        <iframe
          src="https://giphy.com/embed/idFrzUZITaWimMJvY8"
          width="480"
          height="316"
          frameBorder="0"
          class="giphy-embed"
          allowFullScreen
        ></iframe>
      </Box>
      <Box p={3} sx={{ display: reward === 3 ? "block" : "none" }}>
        <iframe
          src="https://giphy.com/embed/s2qXK8wAvkHTO"
          width="270"
          height="178s"
          frameBorder="0"
          class="giphy-embed"
          allowFullScreen
        ></iframe>
      </Box>
      <Box p={3}>
        <h1>{getMessage()}</h1>
        <Typography variant="h3">You earned ${getPoints()} pointss</Typography>
        {/* @todo */}
        <Button>Add a note</Button>
      </Box>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function ResponseDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    <div>
      {/* <Typography variant="subtitle1" component="div">
        Selected: {selectedValue}
      </Typography>
      <br />
      <Button variant="outlined" onClick={handleClickOpen}>
        Open simple dialog
      </Button> */}
      <SimpleDialog
        selectedValue={selectedValue}
        open={props.open}
        onClose={() => props.setCelebrate(false)}
      />
    </div>
  );
}
