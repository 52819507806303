function getApiURL() {
  let url;
  if (
    document.location.href.includes("render") ||
    document.location.href.includes("192") ||
    document.location.href.includes("172")
  ) {
    url = "https://manageme-api.onrender.com";
  } else {
    url = "http://localhost:3000";
  }
  console.log(url);
  return url;
}

export const getTags = (key) => {
  if (key === "domain_list")
    return [
      "work",
      "personal",
      "home",
      "riley",
      "ben",
      "jack",
      "lauren",
      "chores",
      "hygiene",
      "mind",
      "body",
      "soul",
      "environment",
      "career",
      "father",
      "fuel",
      "family",
      "finances",
      "friends",
      "community",
      "fitness",
      "growth",
      "rest",
      "work-goal",
      "work-task",
      "morning",
      "morning-early",
      "morning-breakfast",
      "morning-late",
      "evening-before-kids",
      "evening-kids-home",
      "evening-dinner",
      "evening-after-dinner",
      "evening-after-kids-bed",
      "evening-before-bed",
      "evening",
      "meta",
      "admin",
      "rhythm",
      "health",
      "winTheDay",
      "early-afternoon",
      "mid-afternoon",
      "late-afternoon",
      "cleaning",
      "meals",
      "tidy",
      "organize",
      "water",
      "sand",
      "pebble",
      "rock",
      "why-loving-husband",
      "why-loving-father",
      "why-helpful-team-mate",
      "why-reliable-person",
      "why-child-of-god",
      "why-good-neighbor",
    ].sort();
  else
    return [
      "computer",
      "home",
      "basement",
      "nokids",
      "sunny-day",
      "kitchen",
      "bedroom",
      "office",
      "yard",
      "errands",
      "daylight",
      "no-interuptions",
      "framingham",
      "errands-sudbury",
      "errands-framingham",
      "boston",
      "sudbury-shopping",
    ].sort();
};

export default { getApiURL };
