import React from "react";
import ReactDOM from "react-dom";
import { Formik, Field, Form } from "formik";
import Box from "@mui/material/Box";
import Config from "../ConfigHelper";
import Button from "@mui/material/Button";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "react-query";
import axios from "axios";
import TextField from "@mui/material/TextField";

const API_URL = Config.getApiURL();

//const queryClient = new QueryClient();

// This will work
const WantForm = (props) => {
  const queryClient = useQueryClient();

  // const { isLoading, error, data } = useQuery("fetchLuke", () =>
  //   axios.put("http://swapi.dev/api/people/1/")
  // );

  const mutation = useMutation((formData) => {
    return axios.post(API_URL + "/wants", formData);
  });

  // const mutation = useMutation((formData) => {
  //   return fetch("/api", formData);
  // });

  // const onSubmit = (values) => {
  //   mutation.mutate(new FormData(event.target));
  // };

  // onSubmit={async (values) => {
  //   await new Promise((r) => setTimeout(r, 500));
  //   alert(JSON.stringify(values, null, 2));
  // }}

  return (
    <div>
      {mutation.isLoading ? (
        "Adding want..."
      ) : (
        <>
          {mutation.isError ? (
            <div>An error occurred: {mutation.error.message}</div>
          ) : null}

          {mutation.isSuccess ? <div>Want added!</div> : null}
          <h2>Add something you want</h2>
          <Formik
            initialValues={{
              want: {
                name: "",
                cost: "",
              },
            }}
            onSubmit={(values, actions) => {
              setTimeout(() => {
                mutation.mutate(values);
                // alert(JSON.stringify(values, null, 2));
                actions.setSubmitting(false);
              }, 1000);
            }}
          >
            <Form>
              <Box p={1}>
                <Field
                  id="name"
                  name="want[name]"
                  label="Want"
                  placeholder="This I want..."
                />
              </Box>
              <Box p={1}>
                <Field
                  id="cost"
                  label="Cost"
                  name="want[cost]"
                  placeholder="$100"
                />
              </Box>
              <Box p={1}>
                <Button type="submit" variant="contained">
                  Submit
                </Button>
              </Box>
            </Form>
          </Formik>
        </>
      )}
    </div>
  );
};

export default WantForm;
