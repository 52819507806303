import { useEffect, useRef } from "react";

export const timeDue = (action) => {
  if (action.scheduleType.includes("day", "month", "other")) {
    return "Today";
  } else {
    return action.hourDueReadable;
  }
};

export const displayFilter = (x) => {
  // cant this task be done
};

export const contextFilter = (y) => {
  // driving
  // home
  // out
};

export const periodFilter = (z) => {
  // does it roll over to the next period or expire
  // can it be done multiple times in one period
};

export const timeFilter = (
  available_within_hrs,
  target,
  grace_period_hrs,
  expires_after_hrs
) => {
  // can't give riley dinner @ 9am
};

export const effortFilter = (expected_duration) => {
  // how long will it take
};

export const timeDueNew = (nextDue, scheduleType) => {
  if (scheduleType.includes("day", "month", "other")) {
    return "Today";
  } else {
    return nextDue;
  }
};

export const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};
