import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import StarsIcon from "@mui/icons-material/Stars";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import FiberManualRecordOutlinedIcon from "@mui/icons-material/FiberManualRecordOutlined";
import StarOutlineOutlinedIcon from "@mui/icons-material/StarOutlineOutlined";
import ArrowCircleUpTwoToneIcon from "@mui/icons-material/ArrowCircleUpTwoTone";
import ArrowCircleDownOutlinedIcon from "@mui/icons-material/ArrowCircleDownOutlined";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";

let importance_icons = [
  LightbulbOutlinedIcon,
  InfoOutlinedIcon,
  ArrowCircleDownIcon,
  ArrowCircleUpIcon,
  ErrorOutlineOutlinedIcon,
];

function ImportanceIcon(props) {
  switch (props.importance) {
    case "critical":
      return <StarsIcon />;
    case "high":
      return <ArrowCircleUpTwoToneIcon />;
    case "medium":
      return <CircleOutlinedIcon />;
    case "low":
      return <ArrowCircleDownOutlinedIcon />;
    case "bonus":
      return <StarOutlineOutlinedIcon />;
    default:
      return <LightbulbOutlinedIcon />;
  }
}

export default ImportanceIcon;
